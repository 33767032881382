<template>
  <div id="home">
    <!-- <Header :content="de.headline" /> -->
    <About />
    <Video />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
// import Header from "@/components/Header.vue";
import Video from "@/components/Video.vue";
import About from "@/components/About.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    // Header,
    Video,
    About,
    Footer,
  },
  data() {
    return {
      de: {
        headline: "Making art happen.",
      },
      trigger: null,
      video: null,
    };
  },
};
</script>

<style lang="scss">
#home {
  // display: grid;
  width: 100vw;
  height: 100vh;
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  scroll-snap-points-y: repeat(100vh);
  // position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: -1;

  // @-moz-document url-prefix() {
  //   scroll-snap-type: y proximity;
  // }

  header,
  section,
  footer,
  #about {
    scroll-snap-align: start;
    scroll-snap-stop: normal;
  }
  footer {
    scroll-snap-align: end;
  }
  // footer {
  //   scroll-snap-align: end;
  //   scroll-snap-stop: normal;
  // }
}
</style>
