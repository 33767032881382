<template>
  <section id="about" class="content">
    <Navigation />
    <p style="margin-top: 2rem">
      <strong>HUMO LAB</strong> CONNECTS ARTISTS AND INSTITUTIONS, CURATORS AND
      BRANDS, PRODUCERS AND PLATFORMS, IN ORDER TO PROVIDE THE IDEAL SETUP FOR
      AN IMPACTFUL. MEANINGFUL, AND IMMERSIVE EXPERIENCE OF CONTEMPORARY ART.
    </p>

    <ul style="margin-top: 1rem">
      <li><p>ADVISORY</p></li>
      <li><p>CURATING</p></li>
      <li><p>PRODUCTION</p></li>
      <li><p>EXECUTIVE PRODUCTION</p></li>
    </ul>
  </section>
</template>

<script>
import Navigation from "@/components/Navigation.vue";

export default {
  components: {
    Navigation,
  },
};
</script>

<style lang="scss">
#about {
  position: relative;
}
</style>
