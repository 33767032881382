<template>
  <section id="slider">
    <div class="slider__content">
      <div class="video" @click="unmuteVideo">
        <div class="video__text content">
          <h2 class="h2" v-html="video.headline"></h2>
          <p>{{ video.subline }}</p>
        </div>
        <video
          class="video__player"
          muted="true"
          webkit-playsinline="true"
          playsinline="true"
          preload="metadata"
          loop
        >
          <source :src="video.mp4" type="video/mp4" />
          <source :src="video.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Video",
  data() {
    return {
      video: {
        headline: "Toshihiko Mitsuya<br /><i>Wildflowers</i>",
        subline: "Commission work for Audemars Piguet",
        webm: require("../assets/video/AP_Toshi_16x9.webm"),
        mp4: require("../assets/video/AP_Toshi_16x9.mp4"),
      },
      activeVideo: null,
    };
  },
  methods: {
    playVideo() {
      // this.addTimeListener(this.activeVideo);
      //   console.log("Add Listener");
      this.activeVideo.play();
    },
    pauseVideo() {
      //   console.log("Remove Listener");
      this.activeVideo.pause();
    },
    unmuteVideo() {
      this.activeVideo.muted = !this.activeVideo.muted;
    },
  },
  beforeDestroy() {},
  mounted() {
    this.activeVideo = document.querySelectorAll(".video__player")[0];

    // Intersection Observer
    const observer = new IntersectionObserver(
      ([entry]) => {
        // console.log(entry.intersectionRatio);
        if (
          entry.intersectionRatio >= 1 // to prevent inital fire
        ) {
          this.playVideo();
        } else {
          this.pauseVideo();
        }
      },
      {
        root: document.querySelector("#home"),
        rootMargin: "20px",
        threshold: 1,
      }
    );
    observer.observe(document.getElementById("slider"));
  },
};
</script>

<style lang="scss" scoped>
#slider {
  position: relative;
  width: 100vw;
  height: 100vh;

  .slider__content {
    display: inline-flex;
    width: auto;
    // transform: translateX(-25%);
    transition: transform 0.5s cubic-bezier(0.83, 0, 0.17, 1);
  }

  // overflow: hidden;
}
.video {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .video__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    padding: 0 5% 15vh 5%;

    z-index: 11;

    h2,
    p {
      color: $white;
    }
    h2 {
      margin-bottom: 1em;
    }
  }

  video.video__player {
    position: absolute;
    left: 50%; /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%); /* % of current element */

    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */

    z-index: 10;

    // max-width: 100%;
    // height: 100%;
  }
}
</style>
